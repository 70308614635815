.gradient-container {
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  height: 85vh;
  position: relative;
  width: 100%;
  max-width: 100%;

  .image {
    display: block;
    width: 100%;
    height: auto;
    background-size: contain;
  }

  .overlay-title {
    position: absolute;
    font-family: "Bebas Neue", sans-serif;
    font-size: 365px;
    line-height: 335px;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    mix-blend-mode: soft-light;
    color: #000;
    color: #656b93;
    mix-blend-mode: color-burn;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0.75;
    padding: 20px;
    text-align: center;

    background: $main-linear-gradient;

    background-size: 400% 400%;

    animation: gradient 5s ease infinite;
  }
  @include gradient-animation;

  @media (max-width: 1400px) {
    .overlay-title {
      font-size: 250px;
      line-height: 200px;
    }
  }
  @media (max-width: 800px) {
    .overlay-title {
      font-size: 150px;
      line-height: 115px;
    }
  }
  @media (max-width: 450px) {
    .overlay-title {
      font-size: 100px;
      line-height: 75px;
    }
  }
}
