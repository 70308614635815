.tech-stack-header {
  //   padding-left: 25px;
  //   position: relative;
  //   top: -50%;
  //   left: 10%;

  .tech-stack-head {
    @include center-content;
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    width: 100%;
    margin: 25px 0px;
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgb(85, 84, 84);
  }
}
.tech-stack-icon-wrap {
  @include center-content;
  flex-direction: column;
  padding: 1rem;
  h4 {
    color: #fff;
    text-shadow: 2px 2px 4px rgb(85, 84, 84);
    text-transform: uppercase;
  }
  .tech-stack-icon {
    color: #fff;
    font-size: 3.5rem;
    text-shadow: 2px 2px 4px rgb(85, 84, 84);
  }
  .fa-brands {
    // display: block;
  }
  h4 {
    margin-top: 0.25rem;
  }
}

@media (max-width: 767px) {
  .tech-stack-header .tech-stack-head {
    // margin: 0px;
  }
  .tech-stack-icon-wrap {
    // flex-direction: row;
    h4 {
      font-size: 16px;
      // display: none;
    }
    .tech-stack-icon {
      font-size: 2.5rem;
    }
  }
}
@media (max-width: 575px) {
  .tech-stack-header .tech-stack-head {
    font-size: 2.1rem;
  }
}
@media (max-width: 475px) {
  .tech-stack-header .tech-stack-head {
    font-size: 1.8rem;
  }
}
