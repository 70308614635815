$gradient-one: #9b8bc7;
$gradient-two: #b8d1eb;
$gradient-three: #bce0dd;

// colorful gradient
$theme-color-one: #a49cd9;
$theme-color-two: #a7aed9;
$theme-color-three: #bbd3f2;
$theme-color-four: #bde3f2;
$theme-color-five: #add9d1;

// deep gradient
$deep-gradient-one: #b4b4f9;
$deep-gradient-two: #9eaedb;
$deep-gradient-three: #bbd8f2;
$deep-gradient-four: #add4d9;
$deep-gradient-five: #add9d1;

// complementary
$comp-color-one: #6f8ca6;
$comp-color-two: #def0ff;
$comp-color-three: #bbd8f2;
$comp-color-four: #a68a5e;
$comp-color-five: #f2ddbb;

// temp colors
$temp-navi1: #e1e9ef;
$temp-navi2: #eee3cb;
$temp-navi3: #d7c0ae;
$temp-navi4: #967e76;

// neutral light colors
$main-light-color: #f8f9fb;
$secondary-light-color: #eee;
$tertiary-light-color: #f9f9f9;
$quaternary-light-color: #fff6ea;

// neutral dark colors
$medium-dark: #b7c4cf;

// secondary heading
$color: #656b93;
$color2: #6f8ca6;
$color4: #5d86ab;
$color3: #6e908a;

// linear gradient

$main-linear-gradient: linear-gradient(
  -45deg,
  rgba(180, 180, 249, 1),
  rgba(167, 174, 217, 1),
  rgba(187, 211, 242, 1),
  rgba(189, 227, 242, 1),
  rgba(173, 217, 209, 1)
);
$gradient-secondary-header-gradient: linear-gradient(
  #656b93,
  #5d86ab,
  #6f8ca6,
  #6e908a,
  #656b93
);
$bright-gradient: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);

// font-weight
$main-font-weight: 300;
