// from merix
html::before {
  content: "";
  position: fixed;
  z-index: 9999;
  width: 100vw;
  max-width: 100%;
  height: 4px;
  box-sizing: border-box;
  background-color: $theme-color-five;
  pointer-events: none;
}
html::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  box-sizing: border-box;
  border-width: 4px;
  border-style: solid;
  border-color: $theme-color-five;
  pointer-events: none;
}

// from merix

@keyframes spinningBorder {
  0% {
    animation-timing-function: ease-in;
    border-image: linear-gradient(
        45deg,
        $color 5%,
        $theme-color-five 37.5%,
        $theme-color-four,
        $theme-color-three 62.5%,
        $color 95%
      )
      1;
  }

  50% {
    animation-timing-function: ease-in;
    border-image: linear-gradient(
        135deg,
        $color 5%,
        $theme-color-five 37.5%,
        $theme-color-four,
        $theme-color-three 62.5%,
        $color 95%
      )
      1;
  }

  100% {
    animation-timing-function: ease-in;
    border-image: linear-gradient(
        45deg,
        $color 5%,
        $theme-color-five 37.5%,
        $theme-color-four,
        $theme-color-three 62.5%,
        $color 95%
      )
      1;
  }
}

html {
  scroll-behavior: smooth;
  width: 100vw;
  max-width: 100%;

  // &::before {
  //   content: "";
  //   position: fixed;
  //   z-index: 9999;
  //   width: 100vw;
  //   max-width: 100%;
  //   height: 4px;
  //   box-sizing: border-box;
  //   background-color: $color;
  //   border-image: linear-gradient(
  //       45deg,
  //       $color 5%,
  //       $theme-color-five 37.5%,
  //       $theme-color-four,
  //       $theme-color-three 62.5%,
  //       $color 95%
  //     )
  //     1;
  //   pointer-events: none;
  // }
  // &::after {
  //   content: "";
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 9999;
  //   width: 100vw;
  //   max-width: 100%;
  //   height: 100vh;
  //   box-sizing: border-box;
  //   border-width: 4px;
  //   border-style: solid;
  //   border-color: $color;
  //   border-image: linear-gradient(
  //       45deg,
  //       $color 5%,
  //       $theme-color-five 37.5%,
  //       $theme-color-four,
  //       $theme-color-three 62.5%,
  //       $color 95%
  //     )
  //     1;
  //   pointer-events: none;
  // }
}

body {
  // background-image: url("/assets/images/soft-wallpaper.png");
  background-color: $main-light-color;

  * {
    @include reset-format;
    @include reset-list;

    // color: $color;
  }
  h3 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
  }
  h4 {
    font-family: "Lato", sans-serif;
    font-weight: 700;
  }
  p {
    font-family: "Lato", sans-serif;
  }
}

// BASIC MEDIA QUERY REFERENCE
// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
}
