.why-us-wrapper {
  margin: 100px 0px;

  .why-us-header {
    @include center-content;
    padding: 30px 0;
  }

  .why-us-container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    // background: url("../../../images/team-photo-one.jpg");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 350px;
    width: 100%;
  }
  .why-us-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    // margin-top: 25px;
    // padding: 15px;
    // width: 100%;
  }
  .why-us-content-list-main {
    margin-top: 15px;
  }
  .why-us-content-list {
    padding: 20px;
    color: $color;
  }
  .why-us-header-secondary {
    margin-bottom: 1rem;
  }
  .why-us-list-item {
    padding: 10px;
  }

  @media (max-width: 1199px) {
    .why-us-content {
      display: flex;
      align-content: center;
      justify-content: center;
      // width: 50%;
    }
  }

  @media (max-width: 768px) {
    .why-us-content {
      @include center-content;
      width: 80%;
    }
    .why-us-header {
      @include center-content;
      padding: 20px 0 10px 0;
    }
  }
}
