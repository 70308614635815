.footer-dark {
  @include center-content;
  flex-direction: column;
  text-align: center;
  background-color: $color2;
  color: #fff;
  letter-spacing: 2;
  padding: 25px;
  background-image: url("/assets/images/soft-wallpaper.png");
  .footer-links {
    font-family: "Lato", sans-serif;
  }
}

.footer-logo {
  padding: 25px;
  font-weight: bold;
}

.footer-light {
  background-color: $main-light-color;
  color: #ccc;
  padding: 15px;
}
.social-media-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}
.social-icon {
  padding: 0px 10px;
  font-size: 1.5rem;
}
