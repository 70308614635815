.section-features {
  padding: 5rem 0;

  .container {
    width: 100%;
  }
}

// Price
.display-2 {
  font-size: 7rem;
  letter-spacing: -0.5rem;
  & .currency {
    font-size: 2.75rem;
    position: relative;
    font-weight: $main-font-weight + 100;
    top: -45px;
    letter-spacing: 0px;
  }
  & .period {
    font-size: 1rem;
    color: lighten($color, 20%);
    letter-spacing: 0px;
  }
}
