.btn {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: $main-font-weight + 200;
  color: lighten($color, 15%);
  border-radius: 25px;
  padding: 0.75rem 1.75rem;
  letter-spacing: 3px;
  margin-top: 25px;
}

.header-btn {
  background-color: #f2f2f2;
  transition: background 0.3s ease-in-out;

  &:hover {
    color: white;
    background-color: $gradient-one;
  }
}

.btn:link,
.btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.9375rem 2.5rem;
  display: inline-block;
  border-radius: 6.25rem;
  transition: all 0.2s;
  position: relative;
  margin-top: 1rem;
}

.btn:hover {
  transform: translateY(-0.1875rem);
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(-1px);
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
}

.btn--white {
  background-color: $main-light-color;
  color: #777;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 6.25rem;

  position: absolute;
  top: 0;
  left: 0;

  z-index: -1;

  transition: all 0.4s;
}
.btn-white::after {
  background-color: #fff;
}

.header-btn {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}
