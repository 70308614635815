html {
  width: 100vw;
  max-width: 100%;

  &::before {
    content: "";
    position: fixed;
    z-index: 9999;
    width: 100vw;
    max-width: 100%;
    height: 4px;
    box-sizing: border-box;
    background-color: $theme-color-five;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    box-sizing: border-box;
    border-width: 4px;
    border-style: solid;
    border-color: $theme-color-five;
    pointer-events: none;
  }
}

body {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
  max-width: 100%;

  &::after {
    background-color: $theme-color-five;
  }
  &::before {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100vw;
    max-width: 100%;
    height: 4px;
    background-color: $theme-color-five;
  }
}

body,
body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
}
body::before,
body::after,
body *::before,
body *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
}
