.navigation-container {
  display: block;
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;

  .openMenuButton {
    font-size: 2rem;
    cursor: pointer;
    background-color: transparent;
    color: white;
    padding: 10px 25px;
    border: none;
    mix-blend-mode: color;
  }

  .openMenuButton:hover {
    mix-blend-mode: color;
  }

  .sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background: $main-linear-gradient;
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.25s;

    a,
    button {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-size: 25px;
      color: #818181;
      display: block;
      transition: 0.3s;
      &:hover {
        color: #f1f1f1;
      }
    }

    .closeButton {
      position: absolute;
      top: 0;
      font-size: 2rem;
      background: transparent;
      border: none;
    }

    .nav-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 0px 50px;
      display: grid;
      grid-template-rows: 10fr 2fr;
      grid-template-columns: 1fr;

      .navigation-content-container {
        transition: 0.25s;
        display: flex;
        width: 100%;
        position: relative;
        // height: 400px;
        height: 400px;
        // height: 350px;
        overflow: hidden;

        .left-panel {
          transition: 0.5s;
        }

        .right-panel {
          transition: 0.5s;
          display: flex;

          .nested-left-menu {
            // background-color: greenyellow;
            transition: 0.5s;

            .nav-item-in-focus {
              font-size: 133px;
              color: #fff;
              // color: #fff !important;
              // mix-blend-mode: color-dodge !important;
            }

            .nested-left-menu-content {
              transition: 0.5s;

              .navigation__link {
                padding: 0px;
                cursor: pointer;
                font-size: 2rem;
                font-family: "Bebas Neue", sans-serif;
                // color: red;
                // mix-blend-mode: color-burn;
              }
            }
          }

          .nested-right-menu {
            transition: 0.5s;
            position: relative;
            opacity: 0;

            .nested-right-menu-content {
              color: white;
              transition: 0.5s;
              height: 100%;
              overflow: hidden;

              .back-button {
                margin-bottom: 25px;
                cursor: pointer;
                width: 0px;
                opacity: 0;
              }

              .nested-item-content {
                // height: 500px;
                // max-width: 200px;
                // height: 260px;
                // max-width: 500px;
                // max-height: 350px;
                // --
                // background-color: red;
                // height: 500px;
                // overflow: hidden;
                // position: relative;
                .absolute-nested-nav-item {
                  // position: absolute;
                  // height: 100px;
                  // height: 100%;
                  // width: 100%;
                  // overflow: hidden;
                  // right: -50px;
                  // top: 50px;
                }
                font-family: "Lato", sans-serif;
                // white-space: nowrap;
                h2 {
                  font-size: 16px;
                }
                h4 {
                  font-size: 32px;
                }
                li {
                  font-size: 20px;
                }
                .btn {
                  margin-left: 5px;
                }
              }
            }

            .nested-right-menu {
              transition: 0.5s;
            }
          }
        }
      }

      .navigation-content-footer {
        // padding: 32px 0px;
        margin-bottom: 32px;
        display: grid;
        grid-template-columns: 6fr 6fr;
        width: 100%;

        .navigation-footer-column-lft {
          width: 100%;
          display: flex;
          flex-direction: column;

          .social-title {
            margin-bottom: 16px;
            color: $theme-color-four;
            mix-blend-mode: color-dodge;
          }
          .social-links {
            display: flex;
            a {
              padding: 0px;
            }
            .fa-lg {
              width: 50px;
              color: black;
              mix-blend-mode: soft-light;
              // color: red;
              // mix-blend-mode: color-burn;
            }
          }
        }
        .navigation-footer-column-rgt {
          width: 100%;
          justify-content: end;
          display: flex;
          align-items: end;
        }
      }
    }
  }
}

.width-0-transparent {
  width: 0%;

  * {
    transition: 0.5s;
    color: transparent !important;
  }
}

.width-0 {
  width: 0%;
}

.width-40 {
  width: 40%;
  opacity: 1 !important;
}

.width-60 {
  width: 60%;
  opacity: 1 !important;
}

.width-100 {
  width: 100%;
  opacity: 1 !important;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

@media (max-width: 576px) {
  .navigation-content-footer {
    display: grid;
    grid-template-columns: 12fr !important;
  }
  .navigation-container .sidebar .nav-content .navigation-content-footer {
    .navigation-footer-column-lft {
      align-items: center;
    }
    .navigation-footer-column-rgt {
      justify-content: center;
    }
  }
}
