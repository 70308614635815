.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 55px;

  .company-name {
    letter-spacing: 2px;
  }
}
.navi {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  font-family: "Questrial", sans-serif;

  li {
    text-decoration: none;
    padding: 20px;
    color: #bebebe;

    a {
      text-decoration: none;
    }
    a:link {
      text-decoration: none;
      color: #bebebe;
    }
    a:visited {
      text-decoration: none;
      color: #bebebe;
    }
    a:hover {
      // text-decoration: underline;
      color: #bebebe;
    }

    a:active {
      // text-decoration: underline;
      color: #bebebe;
    }
  }
}
