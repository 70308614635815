//cards
.individual-features {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.feature-element {
  margin: 0 auto;
  height: 300px;
  margin: 1rem;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 15px -3px rgb(0 0 0 / 7%), 0 10px 20px -2px rgb(0 0 0 / 4%);
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding: 1.5625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;

  &:hover:before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#a49cd9, #a49cd9),
      linear-gradient(#bbd3f2, #bbd3f2), linear-gradient(#bde3f2, #bde3f2),
      linear-gradient(#add9d1, #add9d1);
    animation: rotate 4s linear infinite;
  }

  &:hover:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
