@mixin center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin reset-format {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin gradient-animation {
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  &:hover .overlay {
    opacity: 0.8;
  }
}

@mixin card-hover {
  transform: scale(1.025);
  transition: all 1s ease;
  -webkit-box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3);
}
