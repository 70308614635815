.gradient-container {
  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
  // this may be temporary
  clip-path: polygon(0px 0px, 100% 10%, 100% 100%, 0px 90%);
  // this may be temporary
  position: relative;
  width: 100%;
  max-width: 100%;

  .gradient-clip-container {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0.75;
    padding: 20px;
    text-align: center;

    background: $main-linear-gradient;

    background-size: 400% 400%;

    animation: gradient 5s ease infinite;
  }
  @include gradient-animation;
}
.text-box {
  // position: absolute;
  // top: 40%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  // mix-blend-mode: soft-light;
  @include center-content;
  text-align: center;
  flex-direction: column;
}

.heading-primary {
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgb(85, 84, 84);
  font-family: "Lato", sans-serif;
  font-size: 900;
  color: $main-light-color;
}

.heading-primary-main {
  display: block;
  font-size: 124px;
  font-weight: 400;
  letter-spacing: 19px;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  text-align: center;
}

.heading-primary-sub {
  line-height: 3rem;
  display: block;
  font-size: 2.25rem;
  font-weight: 700;
  letter-spacing: 1.0875rem;
  animation: moveInRight 1s ease-out;
  margin: 2rem 0rem;
}

@media (max-width: 900px) {
  .heading-primary-main {
    font-size: 100px;
  }
}
@media (max-width: 800px) {
  .heading-primary-main {
    font-size: 100px;
  }
}
@media (max-width: 775px) {
  .heading-primary-main {
    font-size: 75px;
  }
}

@media (max-width: 625px) {
  .heading-primary-main {
    font-size: 50px;
  }

  .heading-primary-sub {
    font-size: 26px;
  }
}

@media (max-width: 550px) {
  .heading-primary-main {
    font-size: 45px;
  }
  .heading-primary-sub {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .heading-primary-main {
    font-size: 42px;
  }
  .heading-primary-sub {
    font-size: 16px;
  }
}

@media (max-width: 425px) {
  .heading-primary-main {
    font-size: 40px;
  }
}

@media (max-width: 400px) {
  .heading-primary-main {
    font-size: 30px;
  }
  .heading-primary-sub {
    font-size: 12px;
    margin: 1rem 0rem;
  }
}
@media (max-width: 350px) {
  .heading-primary-main {
    font-size: 25px;
  }
}
