.purple-btn {
  border: none;
  border-radius: 25px;
  background-color: $deep-gradient-two;
  color: white;
  font-size: 0.75rem;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  padding: 0.75rem 1.5rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
  // transition: background 0.3s ease-in-out;

  &:hover {
    color: white;
    background-color: lighten($comp-color-one, 5%);
    transform: translateY(-0.1875rem);
    box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.2);
  }
}

// card-group buttons in features
.button {
  @include center-content;
}
