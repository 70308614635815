.gradient-image-container {
  height: 100%;
  width: 100%;
  @include center-content;
  background: linear-gradient(
    -45deg,
    rgba(180, 180, 249, 0.7),
    rgba(187, 211, 242, 0.7),
    rgba(189, 227, 242, 0.7),
    rgba(0.573, 217, 209, 0.7)
  );
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
  @include gradient-animation;
}

.call-to-action {
  @include center-content;
  flex-direction: column;
}

.head-wrap {
  .content-main-heading {
    font-size: 4.5rem;
    // font-size: calc(1.325rem + 0.9vw);
    color: #fff;
    text-align: center;
    width: 100%;
    // mix-blend-mode: color-burn;
    padding-bottom: 10px;
    font-family: "Lato", sans-serif;
    // text-shadow: -15px 5px 20px $color;
    text-transform: uppercase;
    text-shadow: 2px 2px 4px rgb(85, 84, 84);
  }
}

.sub-heading {
  text-align: center;
  padding-bottom: 10px;
  color: #656b93;
  // text-shadow: 2px 2px 4px rgb(85, 84, 84);
}

.button-container {
  @include center-content;
  width: 100%;
}

@media (max-width: 1199px) {
  .head-wrap .content-main-heading {
    // font-size: calc(4.325rem + 0.9vw);
  }
}

@media (max-width: 800px) {
  .head-wrap .content-main-heading {
    font-size: 4rem;
  }
}

@media (max-width: 425px) {
  .head-wrap .content-main-heading {
    font-size: 3rem;
  }
}

@media (max-width: 350px) {
  .head-wrap .content-main-heading {
    font-size: 2rem;
  }
}
