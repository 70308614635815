.features-wrapper {
  padding-bottom: 6rem;
  color: $color;
  .container {
    width: 100%;
  }

  .features-header {
    @include center-content;
    padding: 6rem 0 4rem 0;
    flex-direction: column;

    .secondary-paragraph {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      width: 80%;
      text-align: center;

      font-size: 1.5rem;
    }
  }
}

.feature-icon {
  @include center-content;
  font-size: 4.5rem;
  padding-bottom: 10px;
  color: $color;
}

.element-para {
  text-align: center;
  padding: 1rem;
}

.element-head {
  text-align: center;
  color: $color;
}

@media only screen and (max-width: 1200px) {
  .ourProducts {
    display: flex;
    flex-direction: column;
    padding: 150px;
  }
}
