.gradient-secondary-header {
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Lato", sans-serif;
  display: inline-block;
  text-align: center;
  background-image: -webkit-linear-gradient(
    to right,
    #656b93 25%,
    #5d86ab,
    #6f8ca6 37.5%,
    #6e908a 62.5%,
    #656b93 75%
  );
  background-image: linear-gradient(
    90deg,
    $color 25%,
    $theme-color-five 37.5%,
    $theme-color-four,
    $theme-color-three 62.5%,
    $color 75%
  );
  background-position: right center;
  background-size: 400% auto;
  -webkit-background-clip: text;
  color: $color;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  //   transition: color 1s linear;
  letter-spacing: 0.2rem;
  transition: all 0.7s ease-in-out;
}
.gradient-secondary-header:hover {
  //   transition: color 1s linear;
  transform: scale(1.015);
  text-shadow: 0.25rem 0.65rem 0.75rem rgba(0, 0, 0, 0.3);
  background-position: left center;
  color: $color;
  transition: background-position 2000ms ease-out;
  transition: all 0.7s ease-in-out;
}
@media (max-width: 400px) {
  .gradient-secondary-header {
    font-size: 2rem;
  }
}
