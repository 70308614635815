.card {
  border: 0;
  border-radius: 0px;
  padding: 2.25rem 0;
  margin-bottom: 50px;
  position: relative;
  will-change: transform;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 5px;
    background-color: $gradient-one;
    transition: 0.5s;
  }

  &:hover {
    @include card-hover;

    &:after {
      width: 100%;
    }
  }
  & .card-header {
    background-color: white;
    padding-left: 2rem;
    border-bottom: 0px;
  }
  & .card-title {
    margin-bottom: 1rem;
  }
  & .card-block {
    padding-top: 0;
  }
  & .list-group-item {
    border: 0px;
    padding: 0.25rem;
    color: $color;
    font-weight: $main-font-weight;
  }
}
