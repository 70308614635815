.contact-details {
  padding: 6rem 0 4rem 0;
}
.contact-content {
  background-color: $main-light-color;
  display: flex;
  margin-bottom: 2.5rem;
  padding: 0 1rem 0 1rem;
  flex-direction: column;
  // width: 100%;
}
.contact-header {
  @include center-content;
  padding-bottom: 3rem;
  width: 100%;
}
// .say-hello-text-box {
//   padding-right: 1rem;
//   border-top: 1px solid $gradient-one;
//   margin-bottom: 1rem;
//   margin: 10px 0px;
// }
.contact-form-wrapper,
.contact-item-wrapper {
  // @include center-content;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 2rem;

  textarea {
    width: 100%;
    height: 150px;
    padding: 12px 12px;
    box-sizing: border-box;
    border: 2px solid $gradient-one;
    border-radius: 4px;
    // background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
  }
}

.contact-item {
  margin: 10px 0px;
  padding: 10px 10px;
  border-top: 1px solid $gradient-one;
  color: $color;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contact-item-header {
    text-transform: uppercase;
  }
}

.form-control {
  margin: 10px 0px;
  border-radius: 0px;
  border: none;
  background-color: $main-light-color;
  border-top: 1px solid $gradient-one;
  // border-bottom: 1px solid $gradient-one;

  &::placeholder {
    color: $color;
  }

  &:focus:not(.focus-visible) {
    outline: none;
    box-shadow: none;

    // background-color: $theme-color-two;
    background-color: transparent;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrapper {
    // display: flex;
    // justify-content: flex-start;
    padding: 0;
  }
}
@media only screen and (max-width: 991px) {
  .contact-form-wrapper {
    margin-bottom: 25px;
  }
}
